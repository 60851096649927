import { PublishCdmEventsOptions } from '@jarvis/shell-commons/dist/services/AnalyticsService/types';
import { URLS } from './urls';

const activity = 'UnifiedAcctMgmt-v01';
export const screenPath = '/ReactMyAccountPrivacy/';
export const screenName = 'DataAndPrivacy';
const eventDetailVersion = '1.5.0';

export interface AnalyticsEventPropTypes {
  actionAuxParams?: string;
  action: string;
  activity: string;
  screenPath: string;
  screenName: string;
  screenMode?: string;
  controlDetail?: string;
  version: string;
}

export interface AnalyticsEventWithControlName extends AnalyticsEventPropTypes {
  controlName: string;
}

export const publishEvent = (
  event: AnalyticsEventPropTypes,
  options?: PublishCdmEventsOptions
) => {
  const publishCdmEvents = (window as any).Shell?.v1.analytics.publishCdmEvents;

  if (!publishCdmEvents) {
    return;
  }

  const dateTime = new Date().toISOString();
  const cdmEvents = {
    dateTime,
    eventDetailType:
      'com.hp.cdm.domain.telemetry.type.eventDetail.category.simpleUi.version.1',
    eventCategory: 'simpleUi',
    eventDetail: event,
    version: '1.5.0'
  };

  publishCdmEvents([cdmEvents], options);
};

const ACTIONS = {
  SCREEN_DISPLAYED: 'ScreenDisplayed',
  CONTROL_BUTTON_CLICKED: 'ControlButtonClicked',
  CONTROL_HYPERLINK_CLICKED: 'ControlHyperLinkClicked'
};

export const DataAndPrivacyScreenDisplayed: AnalyticsEventPropTypes = {
  action: ACTIONS.SCREEN_DISPLAYED,
  activity,
  screenPath,
  screenName,
  version: eventDetailVersion
};

export const HpPrivacyStatementHyperLinkClicked: AnalyticsEventWithControlName =
  {
    action: ACTIONS.CONTROL_HYPERLINK_CLICKED,
    activity,
    screenPath,
    screenName,
    controlName: 'HpPrivacyStatementLink',
    controlDetail: URLS.HpPrivacyStatement,
    version: eventDetailVersion
  };

export const YourPrivacyChoicesHyperLinkClicked: AnalyticsEventWithControlName =
  {
    action: ACTIONS.CONTROL_HYPERLINK_CLICKED,
    activity,
    screenPath,
    screenName,
    controlName: 'YourPrivacyChoicesLink',
    controlDetail: URLS.YourPrivacyChoices,
    version: eventDetailVersion
  };

export const PersonalInfoOptOutButtonClicked: AnalyticsEventWithControlName = {
  action: ACTIONS.CONTROL_BUTTON_CLICKED,
  activity,
  screenPath,
  screenName,
  controlName: 'PersonalInfoOptOutButton',
  controlDetail: URLS.PersonalInfoOptOut,
  version: eventDetailVersion
};

export const DeleteAccountAndDataButtonClicked: AnalyticsEventWithControlName =
  {
    action: ACTIONS.CONTROL_BUTTON_CLICKED,
    activity,
    screenPath,
    screenName,
    controlName: 'DeleteAccountAndDataButton',
    controlDetail: URLS.DeleteAccountAndData,
    version: eventDetailVersion
  };
